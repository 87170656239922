<template>
  <div class="withdrawalrecord">
    <!-- 表格start -->
    <tablelist
      :tableArray="tableArray"
      :defaultTable="defaultTable"
      ref="table"
      @currentMethod="currentMethod"
      :isPage="true"
      :total="total"
      :pageSize="params.page_size"
    ></tablelist>
    <!-- 表格end -->
  </div>
</template>
<script>
import api from "../../http/api";
export default {
  components: {
    tablelist: () => import("../components/table"),
  },
  data() {
    return {
      params: {
        bank_type: 0,
        audit_status: 0,
        page_index: 1,
        page_size: 40,
      },
      loading: false,
      total: 0,
      tableH: 0,
      tableArray: [
        {
          key: "create_time",
          name: "提现时间",
        },
        {
          key: "money",
          name: "提现金额",
        },
        {
          key: "withdrawal_state_desc",
          name: "状态",
        },
        {
          key: "msg",
          name: "审核备注",
        },
      ],
      defaultTable: [],
    };
  },
  // created() {
  //   this.getList();
  // },
  activated() {
    this.getList();
  },
  methods: {
    currentMethod(index) {
      this.getList(index);
    },
    //获取列表数据
    async getList(data) {
      let that = this;
      if (data) that.params.page_index = data;
      let result = await api.getWithdrawalList(that.params);
      if (result.code == 200) {
        let res = result.response;
        that.total = res.total;
        that.defaultTable = res && res.data.length != 0 ? res.data : [];
      }
    },
  },
};
</script>
<style lang="less">
.withdrawalrecord {
  width: 100%;
  height: 100%;
}
</style>
